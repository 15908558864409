@font-face {
    font-family: 'daxlinepro-light';
    src: url(./assets/fonts/DaxlinePro-Light.ttf);
  }
  
  @font-face {
    font-family: 'daxlinepro-bold';
    src: url(./assets/fonts/DaxlinePro-Bold.ttf);
  }
  
  
  @font-face {
    font-family: 'daxlinepro-black';
    src: url(./assets/fonts/DaxlinePro-Black.ttf);
  }
  
  * {
    margin: 0;
    padding: 0;
    font-family: 'daxlinepro-light';
  }